<template>
  <div class="col-lg-4">
    <div class="col-lg-10">
      <div class="document_preview--left document_preview--comment">
        <div class="comment__document">
          <div class="comment__document--title">Комментарии к договору</div>

          <div class="comment__document--btn" v-if="type == 'admin'">
            <button
              type="submit"
              @click="dialogStatus = true"
              class="form__button form__button--blue"
            >
              Добавить коментарий
            </button>
          </div>

          <div class="item__column comment__scroll" v-if="comments">
            <div
              class="comment__document--list"
              v-for="(item, index) in comments"
              :key="index"
            >
              <div
                class="comment__item"
                v-bind:class="{ 'comment__item--sucess': item.done == true }"
              >
                <div class="comment__item--top">
                  <div class="comment__item--data">
                    {{ item.created_at }}
                  </div>
                </div>
                <div class="comment__item--info">
                  <div class="comment__item--left">
                    <div class="comment__item--number">
                      {{ index + 1 }}
                    </div>
                  </div>
                  <div class="comment__item--right">
                    <div class="comment__item--title" v-if="item.type == 'contract'">
                      Контракт
                    </div>
                    <div class="comment__item--title" v-else>
                      <span v-if="attachment">
                        №
                        {{
                          attachment.type.name.substring(11, attachment.type.name.length)
                        }}</span
                      >
                      &nbsp; Приложение
                    </div>
                    <div class="comment__item--title">
                      {{ item.text }}
                    </div>
                    <div class="comment__item--autor">
                      <div class="comment__item_autor--icon">
                        <i class="mdi mdi-account"></i>
                      </div>
                      <div class="comment__item_autor--right">
                        <div class="comment__item_autor--name" v-if="item.user">
                          {{ item.user.common_name }}
                        </div>

                        <div
                          class="comment__item_autor--info"
                          v-if="item.user && item.user.role"
                        >
                          {{ item.user.role.name_ru }}
                        </div>
                      </div>
                    </div>

                    <v-checkbox
                      v-if="type == 'user'"
                      @change="doneComment(item.done, item.id)"
                      v-model="item.done"
                      :label="item.done == true ? 'Исправлено' : 'Неисправлено'"
                      color="primary"
                      hide-details
                    ></v-checkbox>
                  </div>
                  <div
                    class="comment__item--delete pointer"
                    @click="deleteComment(item.id)"
                  >
                    <i class="mdi mdi-delete" style="color: #b71c1c"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <v-dialog v-model="dialogStatus" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2"> Добавить комментарий </v-card-title>

        <form class="comment__form" @submit.prevent="addComment()">
          <div class="item__column mb-6">
            <p class="mb-2">Тип комментария</p>
            <v-select
              :items="commentTypes"
              outlined
              :hide-details="true"
              :flat="true"
              item-text="label"
              item-value="value"
              item-color="#000"
              dense
              v-model="commentType"
              required
              class="mb-2"
            ></v-select>
          </div>
          <div class="item__column mb-6" v-if="commentType == 'attachment'">
            <p class="mb-2">Выберите приложение</p>
            <v-select
              :items="contract.attachments"
              outlined
              :hide-details="true"
              :flat="true"
              item-text="type.title"
              item-value="id"
              item-color="#000"
              dense
              v-model="attachmentId"
              required
              class="mb-2"
            ></v-select>
          </div>

          <div class="item__column">
            <p class="mb-2">Комментарии</p>
            <textarea
              class="comment__form__input"
              v-model="comment"
              placeholder="Комментарий"
              required
              rows="4"
              cols="20"
            ></textarea>
          </div>

          <button class="sign__button" type="submit">Добавить</button>
        </form>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  props: [
    "contract",
    "type",
    "comments_collection",
    "commentTypeId",
    "messageType",
    "attachment",
  ],
  components: {},
  data() {
    return {
      attachmentId: "",
      comments: [],
      dialogStatus: false,
      comment: "",
      commentTypes: [
        {
          label: "Договор",
          value: "contract",
        },
        {
          label: "Приложение",
          value: "attachment",
        },
      ],
      commentType: "",
    };
  },
  methods: {
    ...mapActions(["FETCH_COMMENTS"]),
    doneComment(val, id) {
      this.$axios({
        method: "post",
        url: this.$API_URL + this.$API_VERSION + "update/comment",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token_kcmr")}`,
        },
        data: {
          done: val,
          comment_id: id,
          contract_id: this.contract.id,
        },
      })
        .then((response) => {
          if (response.data) {
            this.$toast.open({
              message: "Успешно сохранено",
              type: "success",
              position: "bottom",
              duration: 4000,
              queue: true,
            });
          }
          this.showDocument(this.contract.id);
          this.getDocument(this.contract.id);
        })
        .catch((error) => {
          console.warn(error);
        });
    },
    addComment() {
      let data = {
        doc_id: this.commentType == "contract" ? this.contract.id : this.attachmentId,
        text: this.comment,
        type: this.commentType,
      };
      this.commentTypeId = data.doc_id;
      this.messageType = data.type;
      this.$axios({
        method: "post",
        url: this.$API_URL + this.$API_VERSION + "create/comment",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token_kcmr")}`,
        },
        data: data,
      })
        .then((response) => {
          if (response.data) {
            this.$toast.open({
              message: "Комментарий успешно добавлено",
              type: "success",
              position: "bottom",
              duration: 4000,
              queue: true,
            });
          }
          this.getComments();
          this.dialogStatus = false;
        })
        .catch((error) => {
          console.warn(error);
        });
    },
    convertDate(date) {
      if (date) {
        let d = date.split("T")[0];
        return d.split("-")[2] + "." + d.split("-")[1] + "." + d.split("-")[0];
      }
    },
    deleteComment(id) {
      this.$axios({
        method: "delete",
        url: this.$API_URL + this.$API_VERSION + "delete/comment",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token_kcmr")}`,
        },
        data: {
          id: id,
        },
      })
        .then((response) => {
          this.$toast.open({
            message: response.data.message,
            type: "success",
            position: "bottom",
            duration: 4000,
            queue: true,
          });
          this.getComments();
        })
        .catch((error) => {
          console.warn(error);
        });
    },
    getComments() {
      this.$axios({
        method: "get",
        url:    this.messageType === "attachment" ?      this.$API_URL +
          this.$API_VERSION + "get/comments?doc_id=" +
          this.commentTypeId +
          "&type=" +
          this.messageType :       this.$API_URL +
          this.$API_VERSION + "get/comments?doc_id=" +
          this.commentTypeId +
          "&type=" +
          this.messageType,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token_kcmr")}`,
        },
      })
        .then((response) => {
          this.comments = response.data.data;
        })
        .catch((error) => {
          console.warn(error);
        });
    },
  },
  mounted() {},
  watch: {
    contract() {
      this.getComments();
    },
    messageType() {
      this.getComments();
    },
  },
};
</script>
